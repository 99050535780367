import $ from "jquery"

$(document).on("turbolinks:load", function () {
  $('.tabs__item').on('click', function(e) {
    e.preventDefault()

    const activeClass = 'tabs__item--active'
    $(`.${activeClass}`).removeClass(activeClass)
    $(e.target).addClass(activeClass)

    const type = $(e.target).attr('data-type')
    if (type === 'all') {
      $('.companies__item').removeClass('d-none')
      $('.certificates__item').removeClass('d-none')
      $('.mass_balances__item').removeClass('d-none')
    } else {
      $('.companies__item').addClass('d-none')
      $(`.companies__item--${type}`).removeClass('d-none')
      $('.certificates__item').addClass('d-none')
      $(`.certificates__item--${type}`).removeClass('d-none')
      $('.mass_balances__item').addClass('d-none')
      $(`.mass_balances__item--${type}`).removeClass('d-none')
    }
  })
})
