import $ from "jquery"

$(document).on('click', '.galleries__item', function (e) {
  e.preventDefault()
  const image = $(e.target).parents('.galleries__item').find('img').attr('src')
  $('.gallery-popup').find('img').attr('src', image)
  $('.gallery-popup').addClass('visible')
})

$(document).on('click', '.gallery-popup__close', function (e) {
  e.preventDefault()
  $(e.target).parents('.gallery-popup').removeClass('visible')
})
