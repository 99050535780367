import $ from "jquery";

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

window.updateDonutChart = function (el, percent, donut) {
  percent = Math.round(percent);
  if (percent > 100) {
    percent = 100;
  } else if (percent < 0) {
    percent = 0;
  }
  var deg = Math.round(360 * (percent / 100));

  if (percent > 50) {
    $(`#${el} .pie`).css("clip", "rect(auto, auto, auto, auto)");
    $(`#${el} .right-side`).css("transform", "rotate(180deg)");
  } else {
    $(`#${el} .pie`).css("clip", "rect(0, 0.8em, 0.8em, 0.35em)");
    $(`#${el} .right-side`).css("transform", "rotate(0deg)");
  }

  if (donut) {
    $(`#${el} .right-side`).css("border-width", "0.1em");
    $(`#${el} .left-side`).css("border-width", "0.1em");
    $(`#${el} .shadow`).css("border-width", "0.1em");
  } else {
    $(`#${el} .right-side`).css("border-width", "0.5em");
    $(`#${el} .left-side`).css("border-width", "0.5em");
    $(`#${el} .shadow`).css("border-width", "0.5em");
  }

  $(`#${el} .num`).text(percent);
  $(`#${el} .left-side`).css("transform", "rotate(" + deg + "deg)");

  $(`#${el} .half-circle`).css("border-color", getRandomColor());
};
