import $ from "jquery"
import "air-datepicker/dist/js/datepicker"
import "air-datepicker/dist/js/i18n/datepicker.en"

$(document).on("turbolinks:load", function () {
  $(window).on('scroll', function (e) {
    if ($(window).scrollTop() > 30) {
      $('.navigation').addClass('navigation--active')
    } else {
      $('.navigation').removeClass('navigation--active')
    }
  })

  $('.date-input').on('change', function() {
    $('.julian-date-input').focus()
  })

  $('#product_date').datepicker({
    language: "en",
    position: "top center",
    dateFormat: "yyyy-mm-dd",
    autoClose: true
  })
})
